<!--
 * @Author: jiang
 * @Date: 2021-07-13 11:08:15
 * @Description: 人员考勤列表
-->
<template>
  <el-container style="height: 100%; padding: 0 15px 15px;">
    <!-- 侧边栏 -->
    <el-aside class="g-bg g-mr-15">
      <el-scrollbar
        y
        view-style="padding: 10px;"
      >
        <dept-tree
          ref="tree"
          @change="onCategoryChange"
        ></dept-tree>
      </el-scrollbar>
    </el-aside>

    <el-main class="g-bg">
      <el-scrollbar
        y
        view-style="padding: 15px;"
      >
        <el-form
          ref="form"
          :model="searchData"
          inline
          label-position="top"
        >
          <el-form-item
            label="原部门名称"
            prop="primary_department_name"
          >
            <el-input
              v-model="searchData.primary_department_name"
              clearable
              placeholder="请输入"
            />
          </el-form-item>
          <el-form-item
            label="类型"
            prop="type"
          >
            <el-select
              v-model="searchData.type"
              clearable
            >
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="vertical-align: bottom;">
            <c-btn-search
              @search="onRefresh"
              @reset="onReset"
              @create="onCreate"
            ></c-btn-search>
          </el-form-item>
        </el-form>

        <el-table :data="list">
          <el-table-column
            label="姓名"
            prop="target_user_name"
          />
          <el-table-column
            label="部门"
            prop="primary_department_name"
          />
          <el-table-column
            label="类型"
            prop="type_name"
          />
          <el-table-column
            label="开始时间"
            prop="start_time"
          />
          <el-table-column
            label="结束时间"
            prop="end_time"
          />
          <el-table-column
            label="操作"
            width="160"
            align="center"
          >
            <template slot-scope="{ row }">
              <c-btn-table
                :show="['info', 'update']"
                @info="onInfo(row)"
                @update="onUpdate(row)"
              ></c-btn-table>
            </template>
          </el-table-column>
        </el-table>

        <b-pagination
          :page="page"
          @change="fetchList"
        ></b-pagination>
      </el-scrollbar>
    </el-main>

    <b-dialog
      :title="formData.id ? '更新' : '新建'"
      ref="detailDialog"
      width="700px"
    >
      <detail-view
        :data="formData"
        :loading="submitLoading"
        @submit="onSubmit"
      ></detail-view>
    </b-dialog>

    <b-dialog
      title="详情"
      ref="infoDialog"
      width="600px"
    >
      <info-view :data="currentRow"></info-view>
    </b-dialog>
    
  </el-container>
</template>

<script>
import { getUserAttendancePage, createUserAttendance, updateUserAttendance } from '@/api/user'
import DeptTree from '@/components/tree/dept'
import DetailView from './components/attendance-detail.vue'
import InfoView from './components/attendance-info.vue'
import { attendanceType } from '@/config/dict'

export default {
  components: {
    DeptTree,
    DetailView,
    InfoView,
  },
  data() {
    return {
      typeOptions: attendanceType,
      currentDept: null,
      searchData: {
        type: '',
        primary_department_name: '',
      },
      page: {
        total: 0,
        current: 1,
        size: 15,
      },
      list: [],
      formData: {},
      submitLoading: false,
      currentRow: {},
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    fetchList() {
      const params = {
        department_id: this.currentDept,
        type: this.searchData.type,
        primary_department_name: this.searchData.primary_department_name,
        page: this.page.current,
      }
      getUserAttendancePage(params).then(res => {
        this.list = res.data
        this.page.total = res.total
      })
    },
    onRefresh() {
      this.page.current = 1
      this.fetchList()
    },
    onReset() {
      this.$refs.form.resetFields()
      this.onRefresh()
    },
    onCategoryChange(id) {
      this.currentDept = id
      this.onRefresh()
    },
    onCreate() {
      this.formData = {
        department_id: '',
        target_user_name: '',
        primary_department_name: '',
        type: '',
        time: [],
        leave_reason: '',
        destruction_leave_date: '',
      }

      this.$refs.detailDialog.open()
    },
    onUpdate(row) {
      this.formData = {
        id: row.id,
        department_id: row.department_id,
        target_user_name: row.target_user_name,
        primary_department_name: row.primary_department_name,
        type: row.type,
        time: [row.start_time, row.end_time],
        leave_reason: row.leave_reason,
        destruction_leave_date: row.destruction_leave_date,
      }

      this.$refs.detailDialog.open()
    },
    onInfo(row) {
      this.currentRow = row
      this.$refs.infoDialog.open()
    },
    onSubmit(data) {
      this.submitLoading = true
      const id = data.id
      data.start_time = data.time[0]
      data.end_time = data.time[1]
      if (id) {
        updateUserAttendance(id, data)
          .then(() => {
            this.$message.success('修改成功')
            this.onRefresh()
            this.$refs.detailDialog.close()
          })
          .finally(() => {
            this.submitLoading = false
          })
      } else {
        createUserAttendance(data)
          .then(() => {
            this.$message.success('添加成功')
            this.onRefresh()
            this.$refs.detailDialog.close()
          })
          .finally(() => {
            this.submitLoading = false
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.user-container {
  display: flex;
  height: 100%;
  padding: 0 15px 15px;
}
</style>
