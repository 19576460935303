<!--
 * @Author: jiang
 * @Date: 2021-07-02 09:55:06
 * @Description:
-->
<template>
  <el-form
    ref="form"
    :model="formData"
    :rules="formRules"
    label-position="top"
  >
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item
          label="所在部门"
          prop="department_id"
        >
          <select-dept v-model="formData.department_id"></select-dept>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item
          label="类型"
          prop="type"
        >
          <el-select v-model="formData.type">
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item
          label="人员姓名"
          prop="target_user_name"
        >
          <el-input
            v-model="formData.target_user_name"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item
          label="原单位名称"
          prop="primary_department_name"
        >
          <el-input
            v-model="formData.primary_department_name"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col></el-col>
    </el-row>

    <el-form-item
      label="请假时间"
      prop="time"
    >
      <el-date-picker
        v-model="formData.time"
        type="daterange"
        value-format="yyyy-MM-dd"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
      ></el-date-picker>
    </el-form-item>
    <el-form-item
      label="销假时间"
      prop="destruction_leave_date"
    >
      <el-date-picker
        v-model="formData.destruction_leave_date"
        value-format="yyyy-MM-dd"
        placeholder="请选择时间"
      ></el-date-picker>
    </el-form-item>
    <el-form-item
      label="请假事由"
      prop="leave_reason"
    >
      <el-input
        v-model="formData.leave_reason"
        type="textarea"
        clearable
        :autosize="{minRows: 3}"
        placeholder="请输入"
      ></el-input>
    </el-form-item>
    <div class="g-center">
      <el-button
        type="primary"
        @click="onSubmit"
        :loading="loading"
        icon="el-icon-plus"
      >保存</el-button>
    </div>
  </el-form>
</template>

<script>
import SelectDept from '@/components/select/cascader/dept.vue'
import { attendanceType } from '@/config/dict'

export default {
  components: {
    SelectDept,
  },
  props: {
    data: {},
    loading: {},
  },
  data() {
    return {
      typeOptions: attendanceType,
      formData: {
        department_id: '',
        target_user_name: '',
        primary_department_name: '',
        type: '',
        time: [],
        leave_reason: '',
        destruction_leave_date: '',
      },
      formRules: {
        department_id: [{ required: true, message: '必填项', trigger: 'change' }],
        target_user_name: [{ required: true, message: '必填项', trigger: 'blur' }],
        type: [{ required: true, message: '必填项', trigger: 'change' }],
        time: [{ required: true, message: '必填项', trigger: 'change' }],
      },
    }
  },
  watch: {
    data: {
      handler(val) {
        this.formData = this.$tools.copy(val)
        this.$nextTick(() => {
          this.$refs.form.clearValidate()
        })
      },
      immediate: true,
    },
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit('submit', this.formData)
        }
      })
    },
  },
}
</script>
